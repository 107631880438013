export const logo = require('../assets/images/logo.png');
export const product1 = require('../assets/images/product1.png');
export const first_backimg = require('../assets/images/1.png');
export const second_backimg = require('../assets/images/2.png');
export const third_backimg = require('../assets/images/3.png');
export const fourth_backimg = require('../assets/images/4.png');
export const fifth_backimg = require('../assets/images/5.png');
export const sixth_backimg = require('../assets/images/6.png');
// export const seventh_backimg = require('../assets/images/7.png');
// export const eighth_backimg = require('../assets/images/8.png');
// export const ninth_backimg = require('../assets/images/9.png');

export const sample1 = require('../assets/images/sample1.png');


// key features
export const kf1 = require('../assets/images/kf1.png');
export const kf2 = require('../assets/images/kf2.png');
export const kf3 = require('../assets/images/kf3.png');
export const kf4 = require('../assets/images/kf4.png');
export const kf5 = require('../assets/images/kf5.png');

// loader

export const loaderGif = require('../assets/images/loader.gif');


// reviews

export const review1 = require('../assets/images/reviews/review1.png');
export const review2 = require('../assets/images/reviews/review2.jpg');
export const review3 = require('../assets/images/reviews/review3.jpg');
export const review4 = require('../assets/images/reviews/review4.jpg');
export const review5 = require('../assets/images/reviews/review5.jpg');
export const review6 = require('../assets/images/reviews/review6.jpg');


// logos
export const logo_black = require('../assets/images/logos/logo_black.png');
export const logo_white = require('../assets/images/logos/logo_white.png');
export const logo_color = require('../assets/images/logos/logo_color.png');
export const logo_fill = require('../assets/images/logos/logo_fill.png');

// core team
export const sachin_sir = require('../assets/images/team/sachin_sir.jpeg');
export const shrikant_sir = require('../assets/images/team/shrikant_sir.jpg');

//gallary
export const gallary1 = require('../assets/images/gallary/1.png');
export const gallary2 = require('../assets/images/gallary/2.png');
export const gallary3 = require('../assets/images/gallary/3.png');
export const gallary4 = require('../assets/images/gallary/4.png');
export const gallary5 = require('../assets/images/gallary/5.png');
export const gallary6 = require('../assets/images/gallary/6.png');


// Blogs

export const blog_1 = require('../assets/blogs/images/blog_1.png');
export const blog_2 = require('../assets/blogs/images/blog_2.png');
export const FOFT_blog_1 = require('../assets/blogs/images/FOFT_Blog_1.png');
export const FOFT_blog_2 = require('../assets/blogs/images/FOFT_Blog_2.png');
export const FOFT_blog_3 = require('../assets/blogs/images/FOFT_Blog_3.png');
export const FOFT_blog_4 = require('../assets/blogs/images/FOFT_Blog_4.png');
export const FOFT_blog_5 = require('../assets/blogs/images/FOFT_Blog_5.png');
export const FOFT_blog_6 = require('../assets/blogs/images/FOFT_Blog_6.png');
export const FOFT_blog_7 = require('../assets/blogs/images/FOFT_Blog_7.png');
