import React, { useEffect, useState } from 'react'
import './Home.scss'
import Slider from 'react-slick';
import { gallary1, gallary2, gallary3, gallary4, gallary5, gallary6, logo, logo_black, logo_fill, review1, review2, review3, review4, review5, review6 } from '../../common/images';
import HomeHeader from '../../Components/HomeHeader';
import BestSellingProducts from '../../Components/BestSellingProducts';

import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../Config/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setAllCategories, setAllProducts, setIsLoading } from '../../Redux/globalReducer';
import { useNavigate } from 'react-router-dom';
import OurTeam from '../OurTeam/OurTeam';

import { blogsList } from '../../common/blogResources';
import { Box } from '@mui/material';

import ReadMoreIcon from '@mui/icons-material/ReadMore';


function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [mobileView, setMobileView] = useState(window.innerWidth < 769);

    const { allProducts, userInfo, allCategories } = useSelector(state => state.globalReducer);

    useEffect(() => {
        // dispatch(setIsLoading(true));
        setTimeout(() => {
            dispatch(setIsLoading(false));
        }, 3000);
    }, [])


    useEffect(() => {
        // get firestore data modular 9 
        const getAllProducts = async () => {
            const usersRef = collection(db, "Products");
            let temp = [];
            getDocs(usersRef).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    temp.push(doc.data());
                });
                console.log(temp);

                dispatch(setAllProducts(temp));
            });
        }

        if (allProducts.length === 0) {
            getAllProducts();
        }



        //get all categories

        const getAllCategories = async () => {
            const usersRef = collection(db, "Categories");
            let temp = [];
            getDocs(usersRef).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    temp.push(doc.data());
                });
                console.log(temp);

                dispatch(setAllCategories(temp));
            });
        }

        if (allCategories.length === 0) {
            getAllCategories()
        }

    }, [])

    const goToProductDetails = (product) => {
        navigate('/product-details?id=' + product.id)
    }



    const reviews = [
        {
            id: 1,
            image: review1,
            stars: 5,
            text: "This is very unique toothbrush, which is light weight, soft and biodegradable. I LOVE IT. Thank you Friends of Turtles ",
            name: "Aarav",
            position: "Dhayari, Pune"
        },
        {
            id: 2,
            image: review2,
            stars: 4,
            text: "At this age I am worried of gums. Since I started using Friends of Turtles Toothbrush, I am much relived. It is gentle of gums, cleans teeth neatly and ultimately climate positive. You must try once.",
            name: "Senior Citizen",
            position: "Solapur, Maharashtra"
        },
        {
            id: 3,
            image: review3,
            stars: 5,
            text: "I have a deep appreciation for nature, and I’m genuinely impressed with this toothbrush. I'm committed to using it consistently and will actively encourage my friends and family to do the same.",
            name: "Bhavya",
            position: "Nanded City, Pune"
        },
        {
            id: 4,
            image: review4,
            stars: 4,
            text: "I love my new bamboo toothbrush. This toothbrush is good for the Earth and does not hurt animals. Everyone should use it",
            name: "Krisha",
            position: "Bavdhan, Pune"
        },
        {
            id: 5,
            image: review5,
            stars: 5,
            text: "For my sustainable future, I have started using sustainable products. Thanks Friends of Turtles for the Ecofriendly Oral care.",
            name: "Asmi",
            position: "Katraj, Pune"
        },
        {
            id: 6,
            image: review6,
            stars: 5,
            text: "I really love this toothbrush. It makes my teeth cleaner and Brighter without harming the environment. Friends you must try this, this is amazing.",
            name: "Vihan",
            position: "Hinjewadi, Pune"
        }
    ];

    const settingsCarousal = {
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        pauseOnHover: false,
        dots: true,
        zoom: true,
        lazyLoad: true,
    };

    const carousalData = [
        {
            "className": "slider slider-1",
            "text": {
                "h4": "",
                "h1": ""
            },
            "button": {
                "text": "Shop Now",
                "link": "#",
                "show": false
            }
        },
        {
            "className": "slider slider-2",
            "text": {
                // "h4": "Booked Now",
                // "h1": "Next-gen speed unveiled"
            },
            "button": {
                "text": "Shop Now",
                "link": "#",
                "show": true

            }
        },
        {
            "className": "slider slider-3",
            "text": {
                // "h4": "Discover",
                // "h1": "The beauty of nature"
            },
            "button": {
                "text": "Shop Now",
                "link": "#",
                "show": true
            }
        },
        {
            "className": "slider slider-4",
            "text": {
                // "h4": "Experience",
                // "h1": "Unforgettable moments"
            },
            "button": {
                "text": "Shop Now",
                "link": "#",
                "show": true
            }
        },
        {
            "className": "slider slider-5",
            "text": {
                // "h4": "Enjoy",
                // "h1": "Peace and tranquility"
            },
            "button": {
                "text": "Shop Now",
                "link": "#",
                "show": true
            }
        },
        {
            "className": "slider slider-6",
            "text": {
                // "h4": "Join",
                // "h1": "The turtle conservation"
            },
            "button": {
                "text": "Shop Now",
                "link": "#",
                "show": true
            }
        }
    ]

    const settingsReviews = {
        infinite: true,
        slidesToShow: window.innerWidth < 769 ? 1 : 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        dots: true,
        // add class to control the slider
        className: "reviews_slider",
        lazyLoad: true,
    };

    {/* Blogs */ }

    const blogs = blogsList;

    const settingsBlogs = {
        infinite: true,
        slidesToShow: window.innerWidth < 769 ? 1 : window.innerWidth >= 769 && window.innerWidth < 1200 ? 2 : 3,
        slidesToScroll: 1,
        autoplay: false,
        speed: 2000,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        dots: false,
        // add class to control the slider
        className: "blogs_slider",
        lazyLoad: true,
    };



    const handleVieAllProducts = () => {
        console.log('View All Products')

        // navigate to the products listing page\
        navigate('/categories-listing')
    }

    const goToCatListing = (category) => {
        console.log('category', category);
        navigate('/categories-listing?category=' + category)
    }


    const readBlog = (e, id) => {
        console.log('Read Blog ', e, id);
        navigate(`/read-blog?id=${id}`);
    }

    return (
        <div>
            {/* Header section */}
            <HomeHeader />

            {/* Video*/}
            {/* only for desktop */}

            {
                !mobileView &&
                <div className="video_section">
                    <div className='youtube_embed_holder'>
                        <iframe
                            id="youtube-video"
                            src="https://www.youtube.com/embed/l1luD9DwZtU?playlist=l1luD9DwZtU&mute=1&autoplay=1&loop=1&controls=0&color=white& modestbranding=0&rel=0&playsinline=0&enablejsapi=1&playlist=IkNrrMTrrgQ" title="YouTube video player"
                            frameBorder="®" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
                        </iframe>
                    </div>
                </div>

            }



            {/* carousal banners*/}
            <div>
                <Slider {...settingsCarousal}>
                    {
                        carousalData.map((slide, index) => {
                            return (
                                <div className={slide.className}>
                                    <div className='slider-text'>
                                        <h4>{slide.text.h4}</h4>
                                        <h1>{slide.text.h1}</h1>
                                    </div>
                                    {
                                        slide.button.show &&
                                        <div className='shop_now_btn' onClick={() => handleVieAllProducts()}>
                                            <a href='#'>Shop Now</a>
                                        </div>
                                    }

                                </div>
                            )
                        })
                    }
                </Slider>
            </div>


            {/* showcase numbers */}

            {/* <div className='showcase_numbers_wrapper'>
                <div className="garden_counter_area snipcss-YHQiq">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="witr_counter_single all_counter_color">
                                    <div className="witr_counter_img">
                                        <img
                                            src="https://themexbd.com/htmlpv/foft/assets/images/garden/icon-1.png"
                                            alt="image"
                                        />
                                    </div>
                                    <div className="witr_counter_number_inn">
                                        <h3 className="counter">223</h3>
                                        <span>+</span>
                                        <h4>Completed </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="witr_counter_single all_counter_color">
                                    <div className="witr_counter_img">
                                        <img
                                            src="https://themexbd.com/htmlpv/foft/assets/images/garden/icon-2.png"
                                            alt="image"
                                        />
                                    </div>
                                    <div className="witr_counter_number_inn">
                                        <h3 className="counter">120</h3>
                                        <span>+</span>
                                        <h4>Expert Membar</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="witr_counter_single all_counter_color">
                                    <div className="witr_counter_img">
                                        <img
                                            src="https://themexbd.com/htmlpv/foft/assets/images/garden/icon-3.png"
                                            alt="image"
                                        />
                                    </div>
                                    <div className="witr_counter_number_inn">
                                        <h3 className="counter">520</h3>
                                        <span>+</span>
                                        <h4>Happy Client</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="witr_counter_single all_counter_color">
                                    <div className="witr_counter_img">
                                        <img
                                            src="https://themexbd.com/htmlpv/foft/assets/images/garden/icon-4.png"
                                            alt="image"
                                        />
                                    </div>
                                    <div className="witr_counter_number_inn">
                                        <h3 className="counter">320</h3>
                                        <span>+</span>
                                        <h4>Work Product</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div> */}


            {/* Products categories section */}

            {
                (allCategories.length > 0 && allProducts.length > 0) && <BestSellingProducts />
            }


            {/* Reviews section */}

            <div className='reviews_wrapper'>
                <div className="text-center">
                    <h2 className="section_title_small">Testimonials</h2>
                    {/* <h3 className="section_title_big">What They Are Talking for Our<br />
                        <span className='section_title_small section_title_big'>Happy Clients</span></h3> */}
                    <h3 className='section_title_big back_gradient_anim_text'>Happy Customers</h3>
                </div>


                <Slider {...settingsReviews}>

                    {
                        reviews.map((review) => {
                            return (
                                <div className="review_box_wrapper" key={review.id}>
                                    <div className="review_img">
                                        <img src={review.image} alt="image" />
                                    </div>
                                    <div className="review_details">
                                        <div>
                                            <div className="stars_wrapper">
                                                {[...Array(review.stars)].map((_, index) => (
                                                    <i className="icofont-star highlight_green" key={index}></i>
                                                ))}
                                            </div>
                                            <div className="review_text">
                                                {review.text}
                                            </div>
                                        </div>
                                        <div className="person_details">
                                            <div className="person_name">
                                                {review.name}
                                            </div>
                                            <div className="person_position">
                                                {review.position}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }

                </Slider>
            </div>

            {/* Conservation is our Mission */}

            <section className='conservation_mission_section' >

            </section>

            {/* Blogs */}

            {/* <Blogs homeHeader={false} /> */}

            <div className="home_blogs_container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                        <h2 className="section_title_small">Our Blogs</h2>
                        <h3 className='section_title_big back_gradient_anim_text'>Expert Opinion</h3>
                    </div>
                </div>
                <div className="row blogs_row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <Slider {...settingsBlogs}>
                            {blogs.map((blog) => {
                                return (
                                    <Box className="blog_box" key={blog.id} sx={{ height: '100%' }}>
                                        <div className="blog_image">
                                            <img src={blog.image} alt="blog" />
                                            {
                                                blog.author &&
                                                <div className="blog_author" >
                                                    <h3 className='author_name'>{blog.author}</h3>
                                                    {
                                                        blog.position &&
                                                        <span className='author_position'>{blog.position}</span>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        <div className="blog_content">
                                            <h3>{blog.name}</h3>
                                            <p>{blog.text}</p>
                                        </div>
                                        <div className="blog_footer">
                                            <div className="read_more" onClick={(e) => readBlog(e, blog.id)}>
                                                <ReadMoreIcon /> Read More
                                            </div>
                                        </div>
                                    </Box>
                                    // </>
                                );
                            })
                            }
                        </Slider>
                    </div>

                </div>

            </div>

            {/* End Blogs */}

            {/* our team */}

            <OurTeam homeHeader={false} />




            {/* Footer section */}
            <div className='footer_wrapper' >
                <div className="footer-middle snipcss-533kF">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-6  col-lg-3">
                                <div id="twr_description_widget-2">
                                    <div className="foft-description-area">
                                        <a href="#"><img src={logo_fill} alt="image" style={{
                                            width: '200px',

                                        }} /></a>
                                        <p style={{
                                            paddingRight: '20px',
                                        }}>We are the voice of Voiceless life and their conservation is our mission. That's why we offer eco-friendly alternatives to help replace, reduce, and reject plastic in daily life for the sustainable future.</p>
                                        <p className="phone"><a href="tel: "> </a></p>
                                        <div>
                                            <div className="social-icons">
                                                <a className="facebook" href="https://www.facebook.com/profile.php?id=100085345820697" target='_blank'><i className="icofont-facebook"></i></a>
                                                <a className="instagram" href="https://www.instagram.com/friends.of.turtles?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target='_blank'><i className="icofont-instagram"></i></a>
                                                <a className="Pinterest" href="https://in.pinterest.com/fofturtles/" target='_blank'><i className="icofont-pinterest"></i></a>
                                                <a className="linkedin" href="https://www.linkedin.com/company/friends-of-turtles/?viewAsMember=true" target='_blank'><i className="icofont-linkedin"></i></a>
                                                <a className="youtube" href="https://www.youtube.com/@FriendsofTurtles" target='_blank'><i className="icofont-youtube"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6  col-lg-3">
                                <div className="widget widget_nav_menu">
                                    <h2 className="widget-title">Our Products</h2>
                                    <div className="menu-useful-links-container">
                                        <ul className="menu">
                                            <li><a
                                                onClick={() => goToCatListing('oral_care')}>Oral care</a></li>
                                            <li><a
                                                onClick={() => goToCatListing('hair_care')}>Hair care</a></li>
                                            <li><a
                                                onClick={() => goToCatListing('drink_wares')}
                                            >Drink wares</a></li>
                                            <li><a
                                                onClick={() => goToCatListing('kitchen_cutlery')}
                                            >Kitchen cutlery</a></li>
                                            <li><a
                                                onClick={() => goToCatListing('personal_care')}
                                            >personal care</a></li>
                                            <li><a
                                                onClick={() => goToCatListing('sustainable_stationary')}
                                            >Sustainable Stationary</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="widget about_us">
                                    <h2 className="widget-title">Get In Touch</h2>
                                    <div className="about-footer">
                                        <div className="footer-widget address">
                                            <div className="footer-address">
                                                <div className="footer_s_inner">
                                                    <div className="footer-sociala-icon">
                                                        <i className="icofont-google-map"></i>
                                                    </div>
                                                    <div className="footer-sociala-info">
                                                        <p> FRIENDS OF TURTLES,<br></br>
                                                            Behind Komal Hotel, Dhayari-DSK Road, Dhayari, Pune, Maharashtra, India - 411041</p>
                                                    </div>
                                                </div>
                                                <div className="footer_s_inner">
                                                    <div className="footer-sociala-icon">
                                                        <i className="icofont-phone"></i>
                                                    </div>
                                                    <div className="footer-sociala-info">
                                                        <p>Telephone: +91 9206 822 822</p>
                                                    </div>
                                                </div>
                                                <div className="footer_s_inner">
                                                    <div className="footer-sociala-icon">
                                                        <i className="icofont-envelope-open"></i>
                                                    </div>
                                                    <div className="footer-sociala-info">
                                                        <p>info@fof-turtles.com
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6  col-lg-3">
                                <div className="widget widget_media_gallery">
                                    <h2 className="widget-title">Gallery</h2>
                                    <div className="gallery galleryid-2024 gallery-columns-3 gallery-size-thumbnail">
                                        <figure className="gallery-item">
                                            <div className="gallery-icon landscape">
                                                <a>
                                                    <img src={gallary1} alt="image" />
                                                </a>
                                            </div>
                                        </figure>
                                        <figure className="gallery-item">
                                            <div className="gallery-icon landscape">
                                                <a>
                                                    <img src={gallary2} alt="image" />
                                                </a>
                                            </div>
                                        </figure>
                                        <figure className="gallery-item">
                                            <div className="gallery-icon landscape">
                                                <a>
                                                    <img src={gallary3} alt="image" />
                                                </a>
                                            </div>
                                        </figure>
                                        <figure className="gallery-item">
                                            <div className="gallery-icon landscape">
                                                <a>
                                                    <img src={gallary4} alt="image" />
                                                </a>
                                            </div>
                                        </figure>
                                        <figure className="gallery-item">
                                            <div className="gallery-icon landscape">
                                                <a>
                                                    <img src={gallary5} alt="image" />
                                                </a>
                                            </div>
                                        </figure>
                                        <figure className="gallery-item">
                                            <div className="gallery-icon landscape">
                                                <a>
                                                    <img src={gallary6} alt="image" />
                                                </a>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                            </div>

                            <div className="maintain_by_wrapper">
                                <div className="copyrights_section">
                                    <p>© 2025 Friends of Turtles. All Rights Reserved.</p>
                                </div>
                                <p>Designed & Developed by <a href="https://eudynamystech.in/" target='_blank'>
                                    <span className='company_name'>Eudynamys Technologies PVT LTD</span>
                                </a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default Home