import React, { useEffect, useState } from 'react';
import HomeHeader from "../../Components/HomeHeader";
import './ContactUs.scss';
import API_BASE_URL from '../../Config/config';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';

import { TextField, Button, Box, Typography, FormControl, FormHelperText } from '@mui/material';

const ContactUs = () => {
    const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
    const [showErrorAlert, setShowErrorAlert] = React.useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        mobile: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateField = (name, value) => {
        let error = '';
        if (name === 'name') {
            error = value ? '' : 'Name is required.';
        } else if (name === 'email') {
            const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
            error = value ? (emailRegex.test(value) ? '' : 'Email is not valid.') : 'Email is required.'

        } else if (name === 'mobile') {
            const mobileRegex = /^\+?[1-9]\d{0,3} ?\d{1,14}$/;
            error = value ? (mobileRegex.test(value) ? '' : 'Mobile number is not valid.') : 'Mobile number is required.';
        } else if (name === 'message') {
            error = value ? '' : 'Message is required.';
        }
        setErrors({
            ...errors,
            [name]: error
        });
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsButtonClicked(true);
        const isValid = Object.keys(formData).every((key) => {
            validateField(key, formData[key]);
            setIsButtonClicked(false);
            return !errors[key];
        });
        if (isValid) {
            // Handle form submission
            console.log('Contact form submitted successfully', formData);

            try {
                const response = await fetch(`${API_BASE_URL}/sendInquiry?inquiryFor=contact`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });
                const data = await response.json();
                console.log('====================================');
                console.log('Contact details data ==>> ', data);
                console.log('====================================');

                setShowSuccessAlert(true);
                setFormData({
                    name: '',
                    email: '',
                    mobile: '',
                    message: ''
                });
                setIsButtonClicked(false);
            } catch (error) {
                console.error('Error submitting contact request:', error);
                setShowErrorAlert(true);
                setIsButtonClicked(false);
            }
        }
    };


    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSuccessAlert(false);
    };

    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowErrorAlert(false);
    };

    return (
        <div>
            <HomeHeader pageName={'contact-us'} />
            <div className='contact_us_screen'>
                <div className='contact_us_title'>
                    Contact us
                </div>
                <div className="contact_us_wrapper">
                    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 5 }}>
                        <form onSubmit={handleSubmit}>
                            <FormControl fullWidth margin="normal" error={Boolean(errors.name)}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    required
                                />
                                <FormHelperText>{errors.name}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" error={Boolean(errors.email)}>
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    required
                                />
                                <FormHelperText>{errors.email}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" error={Boolean(errors.mobile)}>
                                <TextField
                                    label="Mobile Number"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    required
                                />
                                <FormHelperText>{errors.mobile}</FormHelperText>
                            </FormControl>
                            <FormControl fullWidth margin="normal" error={Boolean(errors.message)}>
                                <TextField
                                    label="Message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    required
                                />
                                <FormHelperText>{errors.message}</FormHelperText>
                            </FormControl>
                            <button className='foft_button_primary w-100' type="submit" variant="contained" disabled={isButtonClicked}>Submit</button>
                        </form>
                    </Box>
                </div>
            </div>
            <React.Fragment>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={showSuccessAlert}
                    autoHideDuration={5000}
                    onClose={handleSuccessAlertClose}
                    TransitionComponent={Fade} >
                    <Alert
                        onClose={handleSuccessAlertClose}
                        severity="success"
                        sx={{ width: '100%' }}
                    >
                        Your query submitted successfully.
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={showErrorAlert}
                    autoHideDuration={5000}
                    onClose={handleErrorAlertClose}
                    TransitionComponent={Fade}>
                    <Alert
                        onClose={handleErrorAlertClose}
                        severity="error"
                        sx={{ width: '100%' }}
                    >
                        Failed to submit your query.
                    </Alert>
                </Snackbar>
            </React.Fragment>
        </div>
    )
}

export default ContactUs