import React, { useEffect, useState } from 'react';
import './ProductReview.scss';

import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import Text from '@mui/material/Typography';

import { addDoc, collection, serverTimestamp, setDoc, query, where, getDocs, doc } from 'firebase/firestore';
import { db } from '../../Config/firebase';

const ProductReview = ({ onSubmit, initialHeadline = '', initialReview = '', initialRating = 0, productId = null, customerId = null, customerName = '' }) => {
    const [headline, setHeadline] = useState(initialHeadline);
    const [review, setReview] = useState(initialReview);
    const [rating, setRating] = useState(initialRating);
    const [reviewId, setReviewId] = useState(null);

    useEffect(() => {
        const checkExistingReview = async () => {
            const q = query(
                collection(db, 'reviews'),
                where('product_id', '==', productId),
                where('customer_id', '==', customerId)
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const docSnap = querySnapshot.docs[0];
                console.log('====================================');
                console.log('Existing Review:', docSnap.id, docSnap.data());
                console.log('====================================');
                setReviewId(docSnap.id);
                setHeadline(docSnap.data().review_headline);
                setReview(docSnap.data().written_review);
                setRating(docSnap.data().product_rating);
            }
        };

        checkExistingReview();
    }, [productId, customerId]);

    const handleHeadlineChange = (e) => {
        setHeadline(e.target.value);
    }

    const handleReviewChange = (e) => {
        setReview(e.target.value);
    };


    const handleRatingChange = (e, newValue) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('====================================');
        console.log('Rating:', e, newValue);
        console.log('====================================');
        setRating(Number(e.target.value));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('====================================');
        console.log('handleSubmit ', productId, customerId, customerName);
        console.log('====================================');

        const reviewData = {
            product_id: productId,
            customer_id: customerId,
            customer_name: customerName,
            review_headline: headline,
            written_review: review,
            product_rating: rating,
        };
        try {
            if (reviewId) {
                reviewData.updated_at = new Date().getTime();
                await setDoc(doc(db, 'reviews', reviewId), reviewData, { merge: true });
            } else {
                reviewData.created_at = new Date().getTime();
                const docRef = await addDoc(collection(db, 'reviews'), reviewData);
            }
            onSubmit({ isSubmitted: true, data: reviewData });
        } catch (error) {
            console.error('Error adding document: ', error);
            onSubmit({ isSubmitted: false, data: error });
        }
    };

    return (
        <form className='product_review_form' onSubmit={handleSubmit}>
            <div className='review_form_field'>
                <>
                    <Text className="review_form_label">Rating</Text>
                    <Rating
                        className='rating_stars_block product_review_rating'
                        name="simple-controlled"
                        value={rating}
                        // onChange={(event, newValue) => {
                        // setValue(newValue);
                        // }}
                        onChange={handleRatingChange}
                    />
                </>
            </div>
            <div className='review_form_field'>
                <Text className="review_form_label">Headline</Text>
                <TextField
                    required
                    id="outlined-required"
                    // label="Headline"
                    placeholder="What's most important to know?"
                    // helperText="Write a headline for your review."
                    variant="outlined"
                    onChange={handleHeadlineChange}
                    value={headline}
                    fullWidth
                />
            </div>
            <div className='review_form_field'>
                <Text className="review_form_label">Write a review</Text>
                <TextField
                    id="outlined-multiline-static"
                    // label="Write a review"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    placeholder="What did you like or dislike? What did you use this product for?"
                    onChange={handleReviewChange}
                    value={review}
                />
            </div>

            <button type="submit" className='submit_review_button' disabled={rating <= 0}>{reviewId ? 'Update ' : 'Submit '} Review</button>
        </form>
    );
};

export default ProductReview;