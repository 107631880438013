
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './ProductDetails.scss'
import { kf1, kf2, kf3, kf4, kf5, product1 } from '../../common/images';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { db } from '../../Config/firebase';
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
// import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Cart from '../Cart/Cart';
import DoneIcon from '@mui/icons-material/Done';
import CelebrationIcon from '@mui/icons-material/Celebration';
import HomeHeader from '../../Components/HomeHeader';
import { setIsLoading } from '../../Redux/globalReducer';

import ImageZoom from "react-image-zoom";
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import CloseIcon from '@mui/icons-material/Close';
import ProductReview from '../ProductReview/ProductReview';
import { Divider, Rating } from '@mui/material';
import { toast } from 'react-toastify';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import { get } from 'jquery';



const ProductDetails = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [productDetails, setProductDetails] = useState();
    const [quantity, setQuantity] = useState(1);
    const { userInfo } = useSelector(state => state.globalReducer);
    const [openCartPopup, setOpenCartPopup] = useState(false);
    const [mobileView, setMobileView] = useState(window.innerWidth < 769);
    const [selectedImage, setSelectedImage] = useState();

    const [scrollPosition, setScrollPosition] = useState(0);
    const galleryRef = useRef(null);

    useEffect(() => {

        // dispatch(setIsLoading(true));
        console.log(id);
        const getProductDetails = (id) => {
            getDoc(doc(db, "Products", id)).then((doc) => {
                if (doc.exists()) {
                    console.log("Document data:", doc.data());
                    setProductDetails(doc.data());
                    doc.data()?.images && setSelectedImage(doc.data()?.images[0]);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }

                setTimeout(() => {
                    dispatch(setIsLoading(false));
                }, 2000);
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }
        getProductDetails(id);
    }, []);


    const handleQuantityChange = (type) => {
        if (type === 'plus') {
            setQuantity(quantity + 1);
        }
        else if (type === 'minus') {
            if (quantity > 1) {
                setQuantity(quantity - 1);
            }
        }
    }

    const handleAddToCart = () => {
        // set to details to Users collection under Cart subcollection

        // already added to cart then update quantity

        // else add new item to cart

        dispatch(setIsLoading(true));


        const cartCollection = collection(db, 'Users', userInfo?.user_id, 'Cart');
        const q = query(cartCollection, where("id", "==", productDetails.id));
        getDocs(q).then((querySnapshot) => {
            if (querySnapshot.size > 0) {
                console.log('Already added to cart');
                querySnapshot.forEach((doc1) => {
                    console.log(doc1.id, " => ", doc1.data());
                    const cartItemRef = doc(db, "Users", userInfo?.user_id, 'Cart', doc1.id);
                    setDoc(cartItemRef, {
                        id: id,
                        quantity: (doc1.data().quantity + quantity) || 1,
                        updated_at: new Date().getTime(),
                        sell_price: productDetails?.sell_price,
                        user_id: userInfo?.user_id,
                        ...productDetails
                    }, { merge: true }).then(() => {
                        console.log('Document successfully written!');
                        setOpenCartPopup(true);

                        dispatch(setIsLoading(false));

                    }).catch((error) => {
                        console.error("Error writing document: ", error);
                        dispatch(setIsLoading(false));

                    });
                });
            } else {
                console.log('Not added to cart');
                const cartItemRef = doc(db, "Users", userInfo?.user_id, 'Cart', productDetails.id);
                setDoc(cartItemRef, {
                    id: id,
                    quantity: quantity,
                    created_at: new Date().getTime(),
                    updated_at: new Date().getTime(),
                    user_id: userInfo?.user_id,
                    ...productDetails
                }).then(() => {
                    console.log('Document successfully written!');
                    setOpenCartPopup(true);
                    dispatch(setIsLoading(false));

                }).catch((error) => {
                    console.error("Error writing document: ", error);
                    dispatch(setIsLoading(false));
                });
            }
        }).catch((error) => {
            console.log("Error getting documents: ", error);
        });

    }

    const handleBuyNow = () => {
        // go to review page with this product details

        navigate('/review?details=' + JSON.stringify([
            {
                id: id,
                quantity: quantity,
            }
        ]));

    }

    const handleCloseCart = () => {
        console.log('handleIncrement');
        setOpenCartPopup(false);
    };

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    }

    const handleBackBtnClick = () => {
        navigate(-1);
    }

    const getTotalMRP = () => {
        let totalMRP = 0;
        if (productDetails?.actual_price) {
            totalMRP = productDetails?.actual_price * quantity;
        }
        return totalMRP;
    }

    const getTotalDiscount = () => {
        let totalDiscount = 0;
        if (productDetails?.actual_price) {
            totalDiscount = productDetails?.actual_price * quantity - productDetails?.sell_price * quantity;
        }
        return totalDiscount;
    }

    const getTotalAmount = () => {
        let totalAmount = 0;
        if (productDetails?.sell_price) {
            totalAmount = productDetails?.sell_price * quantity;
        }
        return totalAmount;
    }

    const isDeleveryFree = () => {

        if (getTotalAmount() >= 200) {
            return true;
        }

        return false;

    }

    const goToHome = () => {
        navigate('/');
    }


    const handleSignInToContinue = () => {
        // send to login page with redirect url

        // capture current url and remove origin
        const currentUrl = window.location.href;
        const redirectUrl = currentUrl.replace(window.location.origin, '');
        console.log('redirectUrl', redirectUrl);
        navigate('/login?redirectUrl=' + redirectUrl);

    }

    const scrollImages = (direction) => {
        const container = galleryRef.current;
        const scrollAmount = container.clientWidth; // Adjust scroll amount as necessary

        if (direction === 'prev') {
            container.scrollLeft -= scrollAmount;
        } else if (direction === 'next') {
            container.scrollLeft += scrollAmount;
        }
    };

    const zoomProps = {
        width: 350,
        // zoomWidth: 300,
        zoomPosition: 'default',
        img: selectedImage,
        zoomLensStyle: 'opacity: 0.5; background-color: white;',
        scale: 1.5,
        zoomStyle: 'opacity: 1; background-color: white;z-index: 1000;',
    };

    /**
     * Tabs
    */
    const [tabValue, setTabValue] = useState('product_features');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue === 'product_reviews') {
            setisShowRatingEnabled(true);
        } else {
            setisShowRatingEnabled(false);
        }
    };

    // Review

    const [reviewModalIsOpen, setReviewModalIsOpen] = useState(false);

    const [productReviews, setProductReviews] = useState([]);
    const [reviewStartCounts, setReviewStartCounts] = useState({});

    const [isShowRatingEnabled, setisShowRatingEnabled] = useState(false);

    const getProductReviews = async (id) => {
        const q = query(
            collection(db, 'reviews'),
            where('product_id', '==', id),
            orderBy('created_at', 'desc')
        );
        try {
            const querySnapshot = await getDocs(q);
            const tempReviews = [];
            const counts = {};
            querySnapshot.forEach((doc) => {
                console.log(doc.id, " => ", doc.data());
                tempReviews.push(doc.data());
                counts['star_' + doc.data().product_rating] = (counts['star_' + doc.data().product_rating] || 0) + 1;
            });
            setProductReviews(tempReviews);
            setReviewStartCounts(counts);

            console.log('====================================');
            console.log('Product Reviews:', tempReviews, counts);
            console.log('====================================');
        } catch (error) {
            console.log("Error getting documents: ", error);
        }
    };

    useEffect(() => {
        getProductReviews(id);
    }, [id]);

    const writeProductReview = () => {
        setReviewModalIsOpen(true);
    }

    const closeReviewModal = () => {
        setReviewModalIsOpen(false);
    };

    const handleReviewSubmit = (reviewData) => {
        if (reviewData) {
            console.log('Review submitted:', reviewData, id, userInfo);
            // Add logic to handle review submission, e.g., API call
            if (reviewData.isSubmitted) {
                toast.success('Review submitted successfully.');
                closeReviewModal();
                getProductReviews(id);
            } else {
                toast.error('Error submitting review. Please try again.');
            }
        }
    };

    const totalReviews = productReviews.length;
    const starPercentages = {
        star_5: totalReviews ? (reviewStartCounts.star_5 / totalReviews) * 100 : 0,
        star_4: totalReviews ? (reviewStartCounts.star_4 / totalReviews) * 100 : 0,
        star_3: totalReviews ? (reviewStartCounts.star_3 / totalReviews) * 100 : 0,
        star_2: totalReviews ? (reviewStartCounts.star_2 / totalReviews) * 100 : 0,
        star_1: totalReviews ? (reviewStartCounts.star_1 / totalReviews) * 100 : 0,
    };

    const totalSum =
        (reviewStartCounts.star_1 * 1) +
        (reviewStartCounts.star_2 * 2) +
        (reviewStartCounts.star_3 * 3) +
        (reviewStartCounts.star_4 * 4) +
        (reviewStartCounts.star_5 * 5);

    const averageRating = totalReviews ? (totalSum / totalReviews).toFixed(1) : 0;

    const convertToDtae = (timestamp) => {
        let date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    const getAverageStartRatings = () => {
        return (
            <>
                <div className="total_stars_block">
                    {Object.keys(starPercentages).map((star, index) => (
                        <div key={index} className="star_rating_block">
                            <span>{star.split('_')[1]} star</span>
                            <div className="star_bar">
                                <div
                                    className="star_fill"
                                    style={{ width: `${starPercentages[star]}%` }}
                                ></div>
                            </div>
                            <span>{starPercentages[star].toFixed(1)}%</span>
                        </div>
                    ))}
                </div>
                <Divider className='review_divider'></Divider>
            </>
        )
    }

    return (
        <>
            <HomeHeader pageName={'product-details'} />

            <div className="product-details-page">
                {
                    productDetails &&
                    <div>
                        <div className='productname_wrapper'>
                            <div className='back_icon' onClick={handleBackBtnClick}>
                                <KeyboardBackspaceIcon className="back_icon" />
                            </div>
                            <div className='product_name'>
                                {productDetails?.name}
                            </div>
                        </div>

                        {/* one row 3 sections div structure only */}
                        <div className='product_details_wrapper'>
                            <div className='details_sec1'>
                                <div className='product_image'>

                                    {/* <img src={productDetails?.images[0]} alt="product" /> */}
                                    {
                                        !mobileView &&
                                        <ImageZoom {...zoomProps} alt={productDetails?.name} />
                                    }
                                    {
                                        mobileView &&
                                        <img src={selectedImage} alt={productDetails?.name} />
                                    }
                                </div>
                                <div className='product_price'>
                                    Price: &#8377; {productDetails?.actual_price} per piece
                                </div>
                            </div>
                            <div className='details_sec2'>
                                <div className="product_short_review">
                                    {
                                        !mobileView &&
                                        <div className='product_path'>
                                            <span style={{ cursor: 'pointer' }} onClick={goToHome}>Home</span> /  {productDetails?.name}
                                        </div>
                                    }
                                    <div className="average_rating">
                                        {
                                            productReviews.length > 0 &&
                                            <>
                                                <div className="average_rating_stars">
                                                    <Rating className='rating_stars_block' name="half-rating-read" defaultValue={averageRating} precision={0.5} readOnly />
                                                </div> <span className='out-of-rating'>{averageRating}</span>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='key_features_title'>
                                    Key Features
                                </div>
                                <div className='key_features'>
                                    <img className='tag' src={kf1} />
                                    <img className='tag' src={kf2} />
                                    <img className='tag' src={kf3} />
                                    <img className='tag' src={kf4} />
                                    <img className='tag' src={kf5} />

                                </div>

                                <div className='product_images'>
                                    <div className='slide-to-left' onClick={() => scrollImages('prev')}>
                                        <NavigateBeforeOutlinedIcon />
                                    </div>
                                    <div className='product_images_wrapper' ref={galleryRef}>
                                        <div className="products-list">
                                            {productDetails?.images.map((image, index) => (
                                                <div key={index} className="product_image">
                                                    <img
                                                        src={image}
                                                        className="product-img"
                                                        alt="product"
                                                        onClick={() => setSelectedImage(image)}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='slide-to-right' onClick={() => scrollImages('next')}>
                                        <NavigateNextOutlinedIcon />
                                    </div>
                                </div>

                            </div>
                            <div className='details_sec3'>
                                <div className='order_summary_wrapper'>
                                    <div className='free_delivery_banner'>
                                        <div className='banner_text'>
                                            Order Details
                                        </div>
                                    </div>
                                    <div className='payment_details'>
                                        <div className='payment_details_row'>
                                            <div className='payment_details_title'>Quntity</div>
                                            <div className='payment_details_value'>
                                                <div className='plus_minus_wrapper'>
                                                    <div className='plus' onClick={() => handleQuantityChange('minus')}><RemoveIcon /></div>
                                                    <div className='quantity' style={{
                                                        marginTop: '5px'
                                                    }}>{quantity}</div>
                                                    <div className='minus' onClick={() => handleQuantityChange('plus')}><AddIcon /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='horizontal_line'></div>

                                        <div className='payment_details_row'>
                                            <div className='payment_details_title'>Total MRP</div>
                                            <div className='payment_details_value'>&#8377; {getTotalMRP()}</div>
                                        </div>
                                        <div className='horizontal_line'></div>
                                        <div className='payment_details_row'>
                                            <div className='payment_details_title'>Discount</div>
                                            <div className='payment_details_value bold green'>- &#8377;{getTotalDiscount()}</div>
                                        </div>

                                        <div className='horizontal_line'></div>
                                        <div className='payment_details_row'>
                                            <div className='payment_details_title bold green'>Total Amount</div>
                                            <div className='payment_details_value bold green'>&#8377; {getTotalAmount()}</div>
                                        </div>
                                        <div className='horizontal_line'></div>

                                        {
                                            userInfo?.user_id ?
                                                <div className='pay_and_cart_btn'>
                                                    <button className='pay_now_btn' onClick={handleBuyNow}>Buy Now</button>
                                                    <button className='add_to_cart_btn' onClick={handleAddToCart}>Add to cart</button>
                                                </div>

                                                :
                                                // sign in to continue

                                                <div className='pay_and_cart_btn'>
                                                    <button className='pay_now_btn' onClick={() => handleSignInToContinue()}>Sign In to continue</button>
                                                </div>

                                        }
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="product_description">
                            <TabContext value={tabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleTabChange} aria-label="Product Features">
                                        <Tab label="Features" className='product_details_tab product_features_tab' value="product_features" />
                                        <Tab label="Reviews" className='product_details_tab product_reviews_tab' value="product_reviews" />
                                    </TabList>
                                </Box>
                                <TabPanel value="product_features" className='tab_content product_features_content'>
                                    {/* key points for 4 categories Material, who can use it, asdasd,asdasddasdd asd*/}

                                    <div className='key_points_wrapper'>
                                        {
                                            productDetails?.description?.map((item, index) => (
                                                <div className='key_points_div' key={index}>
                                                    <div className='key_points'>
                                                        <div className='key_points_title'>
                                                            {item.key}
                                                        </div>
                                                        <div className='list_of_points'>
                                                            <ul>
                                                                {
                                                                    item.values.map((point, index) => (
                                                                        <li key={index}>{point}</li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel value="product_reviews" className='tab_content product_reviews_content'>
                                    <h2 className='review_heading'>Customer reviews</h2>
                                    <div className="review_container row">
                                        <div className="col-xs-12 col-sm-5 col-md-3 col-lg-3">
                                            <div className="total_reviews_block">
                                                <>
                                                    {
                                                        productReviews.length > 0 &&
                                                        <>
                                                            <div className="average_rating">
                                                                <div className="average_rating_stars">
                                                                    <Rating className='rating_stars_block' name="half-rating-read" defaultValue={averageRating} precision={0.5} readOnly />
                                                                </div> <span className='out-of-rating'>{averageRating} out of 5</span>
                                                            </div>
                                                            <div className="review_count">
                                                                {productReviews.length} ratings
                                                            </div>

                                                            {
                                                                isShowRatingEnabled &&
                                                                getAverageStartRatings()
                                                                // frccTHNcsSOUkerMiIzxWWKd81C2
                                                            }
                                                        </>
                                                    }
                                                </>
                                                <div className="add_review_block">
                                                    <h3 className="sub_heading">Review this product</h3>
                                                    <button type='button' className="add_review_btn" onClick={() => writeProductReview()} >Write a review</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-7 col-md-9 col-lg-9">
                                            <div className="reviews_block">
                                                {productReviews.map((review, index) => (
                                                    <div key={index} className="review_block">
                                                        <div className="review_header">
                                                            <div className="reviewer_name">
                                                                <div className="icon_container">
                                                                    <AccountCircleSharpIcon />
                                                                </div>
                                                                <div className="customer_name">
                                                                    {review.customer_name}
                                                                </div>
                                                            </div>
                                                            <div className="review_date">Reviewed on {convertToDtae(review.created_at)}</div>
                                                        </div>
                                                        <div className="review_rating">
                                                            <div className="rating_stars">
                                                                <Rating className='rating_stars_block' name="half-rating-read" defaultValue={review.product_rating} precision={0.5} readOnly />
                                                            </div>
                                                            <div className="review_headline">{review.review_headline}</div>
                                                        </div>
                                                        <div className="review_content">{review.written_review}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </div>
                        <Divider></Divider>
                        <div className='conservation_text'>
                            Conservation is our Mission
                        </div>

                    </div>
                }

                {/* openCartPopup */}

                <Modal open={openCartPopup}
                    onClose={() => setOpenCartPopup(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableEscapeKeyDown={true}
                    BackdropProps={{ onClick: handleBackdropClick }}
                >
                    <>
                        {/* <BlurredBackground /> */}
                        <div style={{

                            position: 'fixed',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            width: mobileView ? '100%' : '450px', // Adjust the width as needed
                            backgroundColor: 'white', // Background color for the fixed section
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', // Optional: add a shadow for better visibility
                            overflowY: 'auto',

                        }}>
                            <Cart onCloseCart={handleCloseCart} />
                        </div>
                    </>
                </Modal>
                {/* Product Reviews */}
                <Modal
                    open={reviewModalIsOpen}
                    onClose={() => closeReviewModal}
                    aria-labelledby="Product-Review"
                    aria-describedby="Product-Review"
                    disableEscapeKeyDown={true}
                    className='product_review_modal'
                >
                    <Box className="review_modal_box">
                        <div className='review_modal_header'>
                            <h2 className='review_modal_title'>Product Review</h2>
                            <CloseIcon className='close_icon' onClick={closeReviewModal} />
                        </div>
                        <div className="review_modal_body">
                            <ProductReview onSubmit={handleReviewSubmit} productId={id} customerId={userInfo.user_id} customerName={userInfo.first_name + ' ' + userInfo.last_name} />
                        </div>
                    </Box>
                </Modal>

            </div>
        </>
    )
}

export default ProductDetails